import React from "react";
import PageTemplate from "../../components/dataloading/PageTemplate";
import { Typography, Grid } from "@mui/material";
import CreateSimpleButton from "../../components/dataloading/CreateSimpleButton";
import UpdateOnlySimpleButton from "../../components/dataloading/UpdateOnlySimpleButton";
import { usePolicyProfile } from "../../hooks/dataMigration/usePolicyProfile";
import { RefreshIconButton } from "../../components/system/layout/RefreshButton";
function PMProfiles() {
  const { profilesSource, profilesTarget, profilesRelated } =
    usePolicyProfile();

  const master = "display_name_en";
  const uri = "/policy/data/profiles/";

  return (
    <>
      <Typography variant="h4">Policy Management - Profiles</Typography>
      <Grid
        container
        sx={{ p: 2, mx: 2, justifyContent: "flex-end", display: "flex" }}
      >
        <RefreshIconButton />
        {profilesSource && profilesTarget && (
          <CreateSimpleButton
            sourceObjects={profilesSource}
            targetObjects={profilesTarget}
            uri={uri}
            related={profilesRelated}
          />
        )}
        {profilesSource && profilesTarget && (
          <UpdateOnlySimpleButton
            sourceObjects={profilesSource}
            targetObjects={profilesTarget}
            uri={uri}
            master={master}
            related={profilesRelated}
          />
        )}
      </Grid>
      <PageTemplate
        master={master}
        sourceObjects={profilesSource}
        targetObjects={profilesTarget}
      />
    </>
  );
}

export default PMProfiles;
