import { Button, IconButton } from "@mui/material";
import React, { useContext } from "react";
import { BackofficeContext } from "../../../tools/BackofficeContext";
import { RefreshOutlined } from "@mui/icons-material";

function RefreshButton() {
  const { makeRefresh } = useContext(BackofficeContext);
  return (
    <Button variant="outlined" onClick={makeRefresh} sx={{ mx: 1 }}>
      Update
    </Button>
  );
}

export default RefreshButton;

export function RefreshIconButton() {
  const { makeRefresh } = useContext(BackofficeContext);
  return <IconButton onClick={makeRefresh}>
    <RefreshOutlined />
  </IconButton>;
}
