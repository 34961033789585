import { useEffect, useState, useCallback, useContext } from "react";
import { comparisonState, dynamicSort } from "../../tools/utilities";
import { useApi } from "../useApi";
import { BackofficeContext } from "../../tools/BackofficeContext";
import { DataMigrationContext } from "../../contexts/DataMigrationContext";
import { usePolicyObject } from "./usePolicyObject";

export const usePolicyProfile = () => {
  const { source, target } = useContext(DataMigrationContext);
  const { getApi } = useApi();
  const { refresh } = useContext(BackofficeContext);
  const { objectsTarget, objectsSource } = usePolicyObject();
  const [profilesSource, setProfilesSource] = useState([]);
  const [profilesTarget, setProfilesTarget] = useState([]);
  const [profilesRelated, setProfilesRelated] = useState([]);
  const uri = "/policy/data/profiles/";
  const masterKey = ["display_name_en", "platforms"];
  const gatherSource = useCallback(() => {
    getApi(uri, source).then((objects) =>
      setProfilesSource(objects.data.sort(dynamicSort(masterKey[0])))
    );
  }, [getApi, source]);
  const gatherTarget = useCallback(() => {
    getApi(uri, target).then((objects) => {
      setProfilesTarget(objects.data.sort(dynamicSort(masterKey[0])));
    });
  }, [getApi, target]);

  const compare = useCallback(() => {
    profilesSource.length > 0 &&
      setProfilesSource((sourceProfiles) =>
        sourceProfiles.map((sourceProfile) => ({
          state: comparisonState(
            sourceProfile,
            profilesTarget,
            masterKey,
            profilesRelated
          ),
          ...sourceProfile,
        }))
      );
  }, [masterKey, profilesRelated, profilesSource.length, profilesTarget]);
  useEffect(
    () =>
      setProfilesRelated([
        {
          key: "object",
          source: objectsSource,
          target: objectsTarget,
          master: "display_name_en",
          foreignKey: "id",
        },
        {
          key: "parent",
          source: profilesSource,
          target: profilesTarget,
          master:["display_name_en", "platforms"],
          foreignKey: "id",
        },
      ]),
    [objectsSource, objectsTarget, profilesSource, profilesTarget]
  );
  useEffect(() => gatherSource(), [gatherSource, source]);
  useEffect(() => gatherTarget(), [gatherTarget, target]);
  useEffect(() => {
    gatherSource();
    gatherTarget();
  }, [gatherSource, gatherTarget, refresh]);
  useEffect(() => compare(), [profilesTarget, compare]);

  return { profilesSource, profilesTarget,profilesRelated };
};
