export function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

// export function comparisonState(source, targetArray, property, ignore = []) {
//   const targetObject = targetArray.find(
//     (obj) => obj[property] === source[property]
//   );
//   if (!targetObject) {
//     return "-1";
//   }
//   return compareSimple(source, targetObject);

//   function compareSimple(src, target) {
//     let stat = 0;
//     for (const [key, value] of Object.entries(src)) {
//       if (["id", "state", ...ignore].includes(key)) {
//         continue;
//       }
//       if (value && Object.getPrototypeOf(value).isPrototypeOf(Object)) {
//         stat += compareSimple(target[key], value);
//       } else if (value && Array.isArray(value)) {
//         for (let i = 0; i < value.length; i++) {
//           const element = value[i];
//           stat += compareSimple(target[key][i], element);
//         }
//       } else {
//         if (target[key] !== value) {
//           stat += 1;
//         }
//       }
//     }
//     return stat;
//   }
// }

export function comparisonState(
  source,
  targetArray,
  master,
  related = [],
  ignore = []
) {
  let targetObject = null;
  if (Array.isArray(master)) {
    targetObject = targetArray.find((obj) => {
      for(let i=0;i<master.length;i++){
        if( obj[master[i]] !== source[master[i]]){return false}
      }
      return true
    });

  } else {
    targetObject = targetArray.find((obj) => obj[master] === source[master]);
  }
  if (!targetObject) {
    return "-1";
  }
  return compareRelated(source, targetObject);

  function compareRelated(src, target) {
    let stat = 0;
    if (!src || !target) {
      return 0;
    }
    for (const [key, value] of Object.entries(src)) {
      if (["id", "state", ...ignore].includes(key)) {
        continue;
      }
      if (value) {
        const relatedKey = related && related.find((k) => key === k.key);
        if (relatedKey) {
          const sourceKey = relatedKey.source.find(
            (j) => j[relatedKey.foreignKey] === value
          );
          const targetKey = relatedKey.target.find(
            (j) => j[relatedKey.foreignKey] === target[key]
          );
          if (!sourceKey || !targetKey) {
            stat += 1;
          } else {
            if (sourceKey[relatedKey.master] !== targetKey[relatedKey.master]) {
              stat += 1;
            }
          }
        } else if (
          value &&
          Object.getPrototypeOf(value).isPrototypeOf(Object)
        ) {
          stat += compareRelated(target[key], value);
        } else if (value && Array.isArray(value)) {
          for (let i = 0; i < value.length; i++) {
            const element = value[i];
            stat += compareRelated(target[key][i], element);
          }
        } else {
          if (target[key] !== value) {
            stat += 1;
          }
        }
      } else {
        if (target[key]) {
          stat += 1;
        }
      }
    }
    return stat;
  }
}
