import React from "react";
import PageTemplate from "../../components/dataloading/PageTemplate";
import { Typography, Grid } from "@mui/material";
import CreateSimpleButton from "../../components/dataloading/CreateSimpleButton";
import UpdateOnlySimpleButton from "../../components/dataloading/UpdateOnlySimpleButton";
import { usePolicyPolicy } from "../../hooks/dataMigration/usePolicyPolicy";
import { RefreshIconButton } from "../../components/system/layout/RefreshButton";
function Policies() {
  const { policiesSource, policiesTarget, policiesRelated } = usePolicyPolicy();

  const master = "name";
  const uri = "/policy/data/policies/";

  return (
    <>
      <Typography variant="h4">Policy Management - Policies</Typography>
      <Grid
        container
        sx={{ p: 2, mx: 2, justifyContent: "flex-end", display: "flex" }}
      >
        <RefreshIconButton />
        {policiesSource && policiesTarget && (
          <CreateSimpleButton
            sourceObjects={policiesSource}
            targetObjects={policiesTarget}
            uri={uri}
            related={policiesRelated}
          />
        )}
        {policiesSource && policiesTarget && (
          <UpdateOnlySimpleButton
            sourceObjects={policiesSource}
            targetObjects={policiesTarget}
            uri={uri}
            master={master}
            related={policiesRelated}
          />
        )}
      </Grid>
      <PageTemplate
        master={master}
        sourceObjects={policiesSource}
        targetObjects={policiesTarget}
      />
    </>
  );
}

export default Policies;
