import { useEffect, useState, useCallback, useContext } from "react";
import { comparisonState, dynamicSort } from "../../tools/utilities";
import { useApi } from "../useApi";
import { BackofficeContext } from "../../tools/BackofficeContext";
import { DataMigrationContext } from "../../contexts/DataMigrationContext";

export const usePolicyObject = () => {
  const { source, target } = useContext(DataMigrationContext);
  const { getApi } = useApi();
  const { refresh } = useContext(BackofficeContext);
  const [objectsSource, setObjectsSource] = useState([]);
  const [objectsTarget, setObjectsTarget] = useState([]);
  const uri = "/policy/data/objects/";
  const masterKey = "display_name_en";
  const gatherSource = useCallback(() => {
    getApi(uri, source).then((objects) =>
      setObjectsSource(objects.data.sort(dynamicSort(masterKey)))
    );
  }, [getApi, source]);

  const gatherTarget = useCallback(() => {
    getApi(uri, target).then((objects) => {
      setObjectsTarget(objects.data.sort(dynamicSort(masterKey)));
    });
  }, [getApi, target]);

  const compare = useCallback(() => {
    objectsSource.length > 0 &&
      setObjectsSource((sourceObjects) =>
        sourceObjects.map((sourceObject) => ({
          state: comparisonState(sourceObject, objectsTarget, masterKey),
          ...sourceObject,
        }))
      );
  }, [objectsSource.length, objectsTarget]);

  useEffect(() => gatherSource(), [gatherSource, source]);
  useEffect(() => gatherTarget(), [gatherTarget, target]);
  useEffect(() => {
    gatherSource();
    gatherTarget();
  }, [gatherSource, gatherTarget, refresh]);
  useEffect(() => compare(), [objectsTarget, compare]);

  return { objectsSource, objectsTarget };
};
