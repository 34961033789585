import React, { useContext } from "react";
import PageTemplate from "../../components/dataloading/PageTemplate";
import { Typography, Grid } from "@mui/material";
import CreateSimpleButton from "../../components/dataloading/CreateSimpleButton";
import UpdateOnlySimpleButton from "../../components/dataloading/UpdateOnlySimpleButton";
import { DataMigrationContext } from "../../contexts/DataMigrationContext";
import { usePolicyObject } from "../../hooks/dataMigration/usePolicyObject";
import { RefreshIconButton } from "../../components/system/layout/RefreshButton";
function PMObject() {
  const { objectsSource, objectsTarget } = usePolicyObject();
  const master = "display_name_en";
  const uri = "/policy/data/objects/";

  return (
    <>
      <Typography variant="h4">Policy Management - Objects</Typography>
      <Grid
        container
        sx={{ p: 2, mx: 2, justifyContent: "flex-end", display: "flex" }}
      >
        <RefreshIconButton />
        {objectsSource && objectsTarget && (
          <CreateSimpleButton
            sourceObjects={objectsSource}
            targetObjects={objectsTarget}
            uri={uri}
          />
        )}
        {objectsSource && objectsTarget && (
          <UpdateOnlySimpleButton
            sourceObjects={objectsSource}
            targetObjects={objectsTarget}
            uri={uri}
            master={master}
          />
        )}
      </Grid>
      <PageTemplate
        master={master}
        sourceObjects={objectsSource}
        targetObjects={objectsTarget}
      />
    </>
  );
}

export default PMObject;
