import React, { useContext } from "react";
import { BackofficeContext } from "../../../tools/BackofficeContext";
import { InputLabel, Select, MenuItem } from "@mui/material";
function EnvironmentCombo({ onChange, value, label, ...attributes }) {
  const { prdToken, uatToken, locToken } = useContext(BackofficeContext);
  return (
    <>
      <InputLabel id="cmbEnvironment-label">{label}</InputLabel>
      <Select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        labelId="cmbEnvironment-label"
        label={label}
        {...attributes}
      >
        {prdToken && <MenuItem value="prd">Production</MenuItem>}
        {uatToken && <MenuItem value="uat">Development</MenuItem>}
        {locToken && <MenuItem value="loc">Local</MenuItem>}
        {!prdToken && !uatToken && !locToken && (
          <MenuItem value="nan">No Environment Available</MenuItem>
        )}
      </Select>
    </>
  );
}

export default EnvironmentCombo;
