import { useState } from "react";
import { createContext } from "react";

export const DataMigrationContext = createContext({
  source: "uat",
  setSource: () => {},
  target: "prd",
  setTarget: () => {},
  changeTarget: () => {},
  changeSource: () => {},
});

export const useInitializeDataMigrationContext = () => {
  const [source, setSource] = useState("uat");
  const [target, setTarget] = useState("prd");
  const changeSource = (newSource) => {
    if (newSource !== target) {
      setSource(newSource);
    } else {
      setTarget(source);
      setSource(newSource);
    }
  };
  const changeTarget = (newTarget) => {
    if (newTarget !== source) {
      setTarget(newTarget);
    } else {
      setSource(target);
      setTarget(newTarget);
    }
  };
  return { source, setSource, target, setTarget, changeSource, changeTarget };
};
