import { useEffect, useState, useCallback, useContext, useMemo } from "react";
import { comparisonState, dynamicSort } from "../../tools/utilities";
import { useApi } from "../useApi";
import { BackofficeContext } from "../../tools/BackofficeContext";
import { DataMigrationContext } from "../../contexts/DataMigrationContext";
import { usePolicyFilter } from "./usePolicyFilter";

export const usePolicyPolicy = () => {
  const { source, target } = useContext(DataMigrationContext);
  const { getApi } = useApi();
  const { refresh } = useContext(BackofficeContext);
  const { filtersSource, filtersTarget } = usePolicyFilter();
  const [policiesSource, setPoliciesSource] = useState([]);
  const [policiesTarget, setPoliciesTarget] = useState([]);
  const [policiesRelated, setPoliciesRelated] = useState([]);
  const uri = "/policy/data/policies/";
  const masterKey = useMemo(() => ["name", "type"], []);
  const gatherSource = useCallback(() => {
    getApi(uri, source).then((objects) =>
      setPoliciesSource(objects.data.sort(dynamicSort(masterKey[0])))
    );
  }, [getApi, masterKey, source]);
  const gatherTarget = useCallback(() => {
    getApi(uri, target).then((objects) => {
      setPoliciesTarget(objects.data.sort(dynamicSort(masterKey[0])));
    });
  }, [getApi, masterKey, target]);

  const compare = useCallback(() => {
    policiesSource.length > 0 &&
      setPoliciesSource((sourceProfiles) =>
        sourceProfiles.map((sourceProfile) => ({
          state: comparisonState(
            sourceProfile,
            policiesTarget,
            masterKey,
            policiesRelated
          ),
          ...sourceProfile,
        }))
      );
  }, [masterKey, policiesRelated, policiesSource.length, policiesTarget]);
  useEffect(
    () =>
      setPoliciesRelated([
        {
          key: "filter",
          source: filtersSource,
          target: filtersTarget,
          master: "name",
          foreignKey: "id",
        },
      ]),
    [filtersSource, filtersTarget]
  );
  useEffect(() => gatherSource(), [gatherSource, source]);
  useEffect(() => gatherTarget(), [gatherTarget, target]);
  useEffect(() => {
    gatherSource();
    gatherTarget();
  }, [gatherSource, gatherTarget, refresh]);
  useEffect(() => compare(), [policiesTarget, compare]);

  return { policiesSource, policiesTarget, policiesRelated };
};
