import React, { useContext } from "react";
import EnvironmentCombo from "../system/layout/EnvironmentCombo";
import { Grid } from "@mui/material";
import DataTable from "../system/layout/DataTable";
import { DataMigrationContext } from "../../contexts/DataMigrationContext";
import LoadingSkeleton from "./LoadingState";
function PageTemplate({ master, sourceObjects, targetObjects }) {
  const { source, target, changeSource, changeTarget } =
    useContext(DataMigrationContext);

  return (
    <>
      <Grid container spacing={3} sx={{ p: 2 }}>
        <Grid item xs={6} sx={{ p: 2 }}>
          <Grid
            sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}
            align="left"
            direction="column"
          >
            <EnvironmentCombo
              label="Source"
              onChange={changeSource}
              value={source}
              id="cmbSource"
            />
          </Grid>

          {sourceObjects ? (
            <DataTable data={sourceObjects} display={master} selectable />
          ) : (
            <LoadingSkeleton />
          )}
        </Grid>
        <Grid item xs={6}>
          <Grid
            sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}
            align="left"
            direction="column"
          >
            <EnvironmentCombo
              label="Destination"
              onChange={changeTarget}
              value={target}
              id="cmbDestionation"
            />
          </Grid>

          {targetObjects ? (
            <DataTable data={targetObjects} display={master} />
          ) : (
            <LoadingSkeleton />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default PageTemplate;
