import React from "react";
import PageTemplate from "../../components/dataloading/PageTemplate";
import { Typography, Grid } from "@mui/material";
import CreateSimpleButton from "../../components/dataloading/CreateSimpleButton";
import UpdateOnlySimpleButton from "../../components/dataloading/UpdateOnlySimpleButton";
import { RefreshIconButton } from "../../components/system/layout/RefreshButton";
import { usePolicyShellScripts } from "../../hooks/dataMigration/usePolicyShellScript";
function ShellScript() {
  const { shellScriptsSource, shellScriptsTarget, shellScriptsRelated } = usePolicyShellScripts();

  const master = "name";
  const uri = "/policy/data/shellscripts/";

  return (
    <>
      <Typography variant="h4">Policy Management - ShellScripts</Typography>
      <Grid
        container
        sx={{ p: 2, mx: 2, justifyContent: "flex-end", display: "flex" }}
      >
        <RefreshIconButton />
        {shellScriptsSource && shellScriptsTarget && (
          <CreateSimpleButton
            sourceObjects={shellScriptsSource}
            targetObjects={shellScriptsTarget}
            uri={uri}
            related={shellScriptsRelated}
          />
        )}
        {shellScriptsSource && shellScriptsTarget && (
          <UpdateOnlySimpleButton
            sourceObjects={shellScriptsSource}
            targetObjects={shellScriptsTarget}
            uri={uri}
            master={master}
            related={shellScriptsRelated}
          />
        )}
      </Grid>
      <PageTemplate
        master={master}
        sourceObjects={shellScriptsSource}
        targetObjects={shellScriptsTarget}
      />
    </>
  );
}

export default ShellScript;
