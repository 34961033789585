import { useEffect, useState, useCallback, useContext } from "react";
import { comparisonState, dynamicSort } from "../../tools/utilities";
import { useApi } from "../useApi";
import { BackofficeContext } from "../../tools/BackofficeContext";
import { DataMigrationContext } from "../../contexts/DataMigrationContext";
import { usePolicyProfile } from "./usePolicyProfile";
import { usePolicyPolicy } from "./usePolicyPolicy";
import { usePolicyFilter } from "./usePolicyFilter";

export const usePolicyShellScripts = () => {
  const { source, target } = useContext(DataMigrationContext);
  const { getApi } = useApi();
  const { refresh } = useContext(BackofficeContext);
  const { profilesTarget, profilesSource } = usePolicyProfile();
  const { policiesTarget, policiesSource } = usePolicyPolicy();
  const { filtersSource, filtersTarget } = usePolicyFilter();
  const [shellScriptsSource, setShellScriptsSource] = useState([]);
  const [shellScriptsTarget, setShellScriptsTarget] = useState([]);
  const [shellScriptsRelated, setShellScriptsRelated] = useState([]);
  const uri = "/policy/data/shellscripts/";
  const masterKey = "name";
  const gatherSource = useCallback(() => {
    getApi(uri, source).then((objects) =>
      setShellScriptsSource(objects.data.sort(dynamicSort(masterKey)))
    );
  }, [getApi, source]);
  const gatherTarget = useCallback(() => {
    getApi(uri, target).then((objects) => {
      setShellScriptsTarget(objects.data.sort(dynamicSort(masterKey)));
    });
  }, [getApi, target]);

  const compare = useCallback(() => {
    shellScriptsSource.length > 0 &&
      setShellScriptsSource((sourceProfiles) =>
        sourceProfiles.map((sourceProfile) => ({
          state: comparisonState(
            sourceProfile,
            shellScriptsTarget,
            masterKey,
            shellScriptsRelated
          ),
          ...sourceProfile,
        }))
      );
  }, [shellScriptsRelated, shellScriptsSource.length, shellScriptsTarget]);
  useEffect(
    () =>
      setShellScriptsRelated([
        {
          key: "profile",
          source: profilesSource,
          target: profilesTarget,
          master: "display_name_en",
          foreignKey: "id",
        },
        {
          key: "policy",
          source: policiesSource,
          target: policiesTarget,
          master: "name",
          foreignKey: "id",
        },
        {
          key: "filter",
          source: filtersSource,
          target: filtersTarget,
          master: "name",
          foreignKey: "id",
        },
      ]),
    [policiesSource, policiesTarget, profilesSource, profilesTarget]
  );
  useEffect(() => gatherSource(), [gatherSource, source]);
  useEffect(() => gatherTarget(), [gatherTarget, target]);
  useEffect(() => {
    gatherSource();
    gatherTarget();
  }, [gatherSource, gatherTarget, refresh]);
  useEffect(() => compare(), [shellScriptsTarget, compare]);

  return { shellScriptsSource, shellScriptsTarget, shellScriptsRelated };
};
