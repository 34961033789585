import { GetApi, PostApi, PatchApi } from "../../tools/axiosFunctions";

// export async function updateSimple({
//   source,
//   target,
//   selectedSource,
//   results,
//   uri,
//   master,
// }) {
//   // const sourceObjects = await getSortedData(source, uri, master);
//   // const targetObjects = await getSortedData(target, uri, master);

//   for (let i = 0; i < selectedSource.length; i++) {
//     const element = selectedSource[i];
//     let sourceElement = sourceObjects.find((obj) => obj.id === element);
//     const targetElement = targetObjects.find(
//       (obj) => obj[master] === sourceElement[master]
//     );
//     sourceElement.pop("id"); // <---- verify this
//     targetElement
//       ? PatchApi(target, `${uri}${targetElement.id}/`, sourceElement).then(
//           () => {
//             results.append(`${targetElement[master]}\tUpdated.`);
//           }
//         )
//       : results.append(`${sourceElement[master]}\tNot found.`);
//   }
// }

// export async function createSimple({
//   source,
//   target,
//   selectedSource,
//   results,
//   uri,
//   master,
// }) {
//   const sourceObjects = await getSortedData(source, uri, master);
//   for (let i = 0; i < selectedSource.length; i++) {
//     const element = selectedSource[i];
//     let sourceElement = sourceObjects.find((obj) => obj.id === element);
//     delete sourceElement.id;
//     sourceElement
//       ? PostApi(target, `${uri}/`, sourceElement).then(
//           () => {
//             results.append(`${source[master]}\tUpdated.`);
//           }
//         )
//       : results.append(`${sourceElement[master]}\tNot found.`);
//   }
// }

export function createSimple(
  target,
  selected,
  uri,
  setProgress,
  successful,
  failed,
  related = []
) {
  for (let i = 0; i < selected.length; i++) {
    const item = selected[i];
    let newItem = { ...item };
    for (let j = 0; j < related.length; j++) {
      const relatedKey = related[j];
      if (item[relatedKey.key]) {
        const sourceRelated = relatedKey.source.find(
          (k) => k[relatedKey.foreignKey] === item[relatedKey.key]
        );
        // masterKeyFind(item,relatedKey.source,relatedKey.master)
        if (!sourceRelated) {
          continue;
        }
        let targetRelated = null;
        try {
          targetRelated = masterKeyFind(
            sourceRelated,
            relatedKey.target,
            relatedKey.master
          );
        } catch (e) {
          // console.error(e)
          // console.log(relatedKey);
          // console.log(sourceRelated)
          // console.log(item)
        }
        // relatedKey.target.find(
        //   (k) => k[relatedKey.master] === sourceRelated[relatedKey.master]
        // );
        if (targetRelated) {
          console.log(targetRelated);
          newItem[relatedKey.key] = targetRelated[relatedKey.foreignKey]; //assign the related element if found
        } else {
          newItem[relatedKey.key] = null; // delete the related element if not found
        }
      }
    }

    delete newItem.id; // remove the Id of the sourceBody
    delete newItem.state; // remove the state of the sourceBody
    newItem
      ? PostApi(target, `${uri}`, newItem).then(() => {
          successful(newItem);
        })
      : failed(newItem);
    setProgress((p) => p + 1);
  }
}

function masterKeyFind(source, collection, master) {
  let targetObject = null;
  if (Array.isArray(master)) {
    targetObject = collection.find((obj) => {
      for (let i = 0; i < master.length; i++) {
        if (obj[master[i]] !== source[master[i]]) {
          return false;
        }
      }
      return true;
    });
  } else {
    targetObject = collection.find((obj) => obj[master] === source[master]);
  }
  return targetObject;
}

export function updateSimple(
  target,
  selected,
  targetObjects,
  master,
  uri,
  setProgress,
  successful,
  failed,
  related = []
) {
  for (let i = 0; i < selected.length; i++) {
    const item = selected[i];
    const targetElement = targetObjects.find(
      (obj) => obj[master] === item[master]
    );
    for (let j = 0; j < related.length; j++) {
      const relatedKey = related[j];
      if (item[relatedKey.key]) {
        const sourceRelated = relatedKey.source.find(
          (k) => k[relatedKey.foreignKey] === item[relatedKey.key]
        );
        const targetRelated = relatedKey.target.find(
          (k) => k[relatedKey.master] === sourceRelated[relatedKey.master]
        );
        if (targetRelated) {
          item[relatedKey.key] = targetRelated[relatedKey.foreignKey];
        } else {
          delete item[relatedKey.key];
        }
      }
    }
    delete item.id; // remove the Id of the sourceBody
    targetElement
      ? PatchApi(target, `${uri}${targetElement.id}/`, item).then(() => {
          successful(item);
        })
      : failed(item);
    setProgress((p) => p + 1);
  }
}
