import React, { useState, useEffect, useContext } from "react";
import { BackofficeContext } from "../../../tools/BackofficeContext";
import { useGetApiFromContext } from "../../../tools/axiosFunctions";
import { Box, Skeleton } from "@mui/material";
import ListSelector from "../../system/layout/ListSelector";
import DropdownFilterOptions from "../../system/layout/DropdownFilterOptions";
function TenantsSelector({ setAudience }) {
  const getApiFromContext = useGetApiFromContext();
  const { environment } = useContext(BackofficeContext);
  const [tenantList, setTenantList] = useState([]);
  const [filterOptions, setFilterOptions] = useState(false);
  const [filteredTenants, setFilteredTenants] = useState([]);
  const getData = async () => {
    getApiFromContext("/auth/backoffice/tenant/").then((response) => {
      setTenantList(
        response.data.map((t) => ({
          label: t.name,
          id: t.tenant_id,
          name: t.name,
          association: t.association,
          windows_pm: t.management_info?.windowsPolicyManagement ? "Yes" : "No",
          ipadOnboarded:
            t.management_info?.ipadOnboarded === true ? "Yes" : "No",
          macosOnboarded:
            t.management_info?.macosOnboarded === true ? "Yes" : "No",
          ipadPolicyManagement:
            t.management_info?.ipadPolicyManagement === true ? "Yes" : "No",
          macosPolicyManagement:
            t.management_info?.macosPolicyManagement === true ? "Yes" : "No",
        }))
      );
      setFilterOptions({
        "Tenant Id": Array.from(new Set(response.data.map((t) => t.tenant_id))),
        Name: Array.from(
          new Set(response.data.map((t) => t.name).filter((v) => v !== null))
        ),
        Association: Array.from(
          new Set(
            response.data.map((t) => t.association).filter((v) => v !== null)
          )
        ),
        "Windows Policy Mgmt": ["Yes", "No"],
        "iPad Management": ["Yes", "No"],
        "macOS Management": ["Yes", "No"],
        "iPad Policy Management": ["Yes", "No"],
        "macOS Policy Management": ["Yes", "No"],
      });
    });
  };
  useEffect(() => {
    setFilteredTenants(tenantList);
  }, [tenantList]);

  const handleFilter = (property, value) => {
    if (value == "") setFilteredTenants(tenantList);
    switch (property) {
      case "Tenant Id":
        setFilteredTenants(tenantList.filter((t) => t.id === value));
        break;
      case "Association":
        setFilteredTenants(tenantList.filter((t) => t.association == value));
        break;
      case "Windows Policy Mgmt":
        setFilteredTenants(tenantList.filter((t) => t.windows_pm === value));
        break;
      case "iPad Management":
        setFilteredTenants(tenantList.filter((t) => t.ipadOnboarded === value));
        break;
      case "macOS Management":
        setFilteredTenants(
          tenantList.filter((t) => t.macosOnboarded === value)
        );
        break;
      case "iPad Policy Management":
        setFilteredTenants(
          tenantList.filter((t) => t.ipadPolicyManagement === value)
        );
        break;
      case "macOS Policy Management":
        setFilteredTenants(
          tenantList.filter((t) => t.macosPolicyManagement === value)
        );
        break;
      case "Name":
        setFilteredTenants(tenantList.filter((t) => t.name === value));
        break;
    }
  };

  useEffect(() => {
    setTenantList([]);
    setFilterOptions(null);
    setAudience && setAudience([]);
    getData();
  }, [environment]);
  return (
    <Box sx={{ py: 2 }}>
      {filterOptions ? (
        <DropdownFilterOptions
          options={filterOptions}
          handleFilter={handleFilter}
        />
      ) : (
        <Box sx={{ display: "flex" }}>
          <Skeleton
            variant="rectangular"
            sx={{ mx: 1 }}
            width={120}
            height={60}
          />
          <Skeleton
            variant="rectangular"
            sx={{ mx: 1 }}
            width={500}
            height={60}
          />
        </Box>
      )}
      {
        <ListSelector
          available={filteredTenants}
          setAudience={setAudience}
          title="Tenants"
        />
      }
    </Box>
  );
}

export default TenantsSelector;
