import { useEffect, useState, useCallback, useContext } from "react";
import { comparisonState, dynamicSort } from "../../tools/utilities";
import { useApi } from "../useApi";
import { BackofficeContext } from "../../tools/BackofficeContext";
import { DataMigrationContext } from "../../contexts/DataMigrationContext";

export const usePolicyFilter = () => {
  const { source, target } = useContext(DataMigrationContext);
  const { getApi } = useApi();
  const { refresh } = useContext(BackofficeContext);
  const [filtersSource, setFiltersSource] = useState([]);
  const [filtersTarget, setFiltersTarget] = useState([]);
  const uri = "/policy/data/filters/";
  const masterKey = "name";
  const gatherSource = useCallback(() => {
    getApi(uri, source).then((objects) =>
      setFiltersSource(objects.data.sort(dynamicSort(masterKey)))
    );
  }, [getApi, source]);

  const gatherTarget = useCallback(() => {
    getApi(uri, target).then((objects) => {
      setFiltersTarget(objects.data.sort(dynamicSort(masterKey)));
    });
  }, [getApi, target]);

  const compare = useCallback(() => {
    filtersSource.length > 0 &&
      setFiltersSource((sourceObjects) =>
        sourceObjects.map((sourceObject) => ({
          state: comparisonState(sourceObject, filtersTarget, masterKey),
          ...sourceObject,
        }))
      );
  }, [filtersSource.length, filtersTarget]);

  useEffect(() => gatherSource(), [gatherSource, source]);
  useEffect(() => gatherTarget(), [gatherTarget, target]);
  useEffect(() => {
    gatherSource();
    gatherTarget();
  }, [gatherSource, gatherTarget, refresh]);
  useEffect(() => compare(), [filtersTarget, compare]);

  return { filtersSource, filtersTarget };
};
