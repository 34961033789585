import React from "react";
import PageTemplate from "../../components/dataloading/PageTemplate";
import { Typography, Grid } from "@mui/material";
import CreateSimpleButton from "../../components/dataloading/CreateSimpleButton";
import UpdateOnlySimpleButton from "../../components/dataloading/UpdateOnlySimpleButton";
import { RefreshIconButton } from "../../components/system/layout/RefreshButton";
import { usePolicyRemediations } from "../../hooks/dataMigration/usePolicyRemediation";
function Remediation() {
  const { remediationsSource, remediationsTarget, remediationsRelated } = usePolicyRemediations();

  const master = "name";
  const uri = "/policy/data/remediations/";

  return (
    <>
      <Typography variant="h4">Policy Management - Remediations</Typography>
      <Grid
        container
        sx={{ p: 2, mx: 2, justifyContent: "flex-end", display: "flex" }}
      >
        <RefreshIconButton />
        {remediationsSource && remediationsTarget && (
          <CreateSimpleButton
            sourceObjects={remediationsSource}
            targetObjects={remediationsTarget}
            uri={uri}
            related={remediationsRelated}
          />
        )}
        {remediationsSource && remediationsTarget && (
          <UpdateOnlySimpleButton
            sourceObjects={remediationsSource}
            targetObjects={remediationsTarget}
            uri={uri}
            master={master}
            related={remediationsRelated}
          />
        )}
      </Grid>
      <PageTemplate
        master={master}
        sourceObjects={remediationsSource}
        targetObjects={remediationsTarget}
      />
    </>
  );
}

export default Remediation;
