import React from "react";
import PageTemplate from "../../components/dataloading/PageTemplate";
import { Typography, Grid } from "@mui/material";
import CreateSimpleButton from "../../components/dataloading/CreateSimpleButton";
import UpdateOnlySimpleButton from "../../components/dataloading/UpdateOnlySimpleButton";
import { usePolicySettings } from "../../hooks/dataMigration/usePolicySettings";
import { RefreshIconButton } from "../../components/system/layout/RefreshButton";

function PMSettings() {
  const { settingsSource, settingsTarget, settingsRelated } =
    usePolicySettings();

  const master = "name";
  const uri = "/policy/data/settings/";

  return (
    <>
      <Typography variant="h4">Policy Management - Settings</Typography>
      <Grid
        container
        sx={{ p: 2, mx: 2, justifyContent: "flex-end", display: "flex" }}
      >
        <RefreshIconButton />
        {settingsSource && settingsTarget && (
          <CreateSimpleButton
            sourceObjects={settingsSource}
            targetObjects={settingsTarget}
            uri={uri}
            related={settingsRelated}
          />
        )}
        {settingsSource && settingsTarget && (
          <UpdateOnlySimpleButton
            sourceObjects={settingsSource}
            targetObjects={settingsTarget}
            uri={uri}
            master={master}
            related={settingsRelated}
          />
        )}
      </Grid>
      <PageTemplate
        master={master}
        sourceObjects={settingsSource}
        targetObjects={settingsTarget}
      />
    </>
  );
}

export default PMSettings;
