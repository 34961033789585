import { useEffect, useState, useCallback, useContext } from "react";
import { comparisonState, dynamicSort } from "../../tools/utilities";
import { useApi } from "../useApi";
import { BackofficeContext } from "../../tools/BackofficeContext";
import { DataMigrationContext } from "../../contexts/DataMigrationContext";
import { usePolicyProfile } from "./usePolicyProfile";
import { usePolicyPolicy } from "./usePolicyPolicy";

export const usePolicySettings = () => {
  const { source, target } = useContext(DataMigrationContext);
  const { getApi } = useApi();
  const { refresh } = useContext(BackofficeContext);
  const { profilesTarget, profilesSource } = usePolicyProfile();
  const { policiesTarget, policiesSource } = usePolicyPolicy();
  const [settingsSource, setSettingsSource] = useState([]);
  const [settingsTarget, setSettingsTarget] = useState([]);
  const [settingsRelated, setSettingsRelated] = useState([]);
  const uri = "/policy/data/settings/";
  const masterKey = "name";
  const gatherSource = useCallback(() => {
    getApi(uri, source).then((objects) =>
      setSettingsSource(objects.data.sort(dynamicSort(masterKey)))
    );
  }, [getApi, source]);
  const gatherTarget = useCallback(() => {
    getApi(uri, target).then((objects) => {
      setSettingsTarget(objects.data.sort(dynamicSort(masterKey)));
    });
  }, [getApi, target]);

  const compare = useCallback(() => {
    settingsSource.length > 0 &&
      setSettingsSource((sourceProfiles) =>
        sourceProfiles.map((sourceProfile) => ({
          state: comparisonState(sourceProfile, settingsTarget, masterKey,settingsRelated),
          ...sourceProfile,
        }))
      );
  }, [settingsRelated, settingsSource.length, settingsTarget]);
  useEffect(
    () =>
      setSettingsRelated([
        {
          key: "profile",
          source: profilesSource,
          target: profilesTarget,
          master:["display_name_en", "platforms"],
          foreignKey: "id",
        },
        {
          key: "policy",
          source: policiesSource,
          target: policiesTarget,
          master: ["name", "type"],
          foreignKey: "id",
        },
      ]),
    [policiesSource, policiesTarget, profilesSource, profilesTarget]
  );
  useEffect(() => gatherSource(), [gatherSource, source]);
  useEffect(() => gatherTarget(), [gatherTarget, target]);
  useEffect(() => {
    gatherSource();
    gatherTarget();
  }, [gatherSource, gatherTarget, refresh]);
  useEffect(() => compare(), [settingsTarget, compare]);

  return { settingsSource, settingsTarget,settingsRelated };
};
