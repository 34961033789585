import React from "react";
import { Box, Skeleton } from "@mui/material";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2, // Spacing between accordions
      }}
    >
      {Array.from({ length: 5 }).map((_, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            border: "1px solid lightgray",
            borderRadius: "8px",
            padding: 2,
          }}
        >
          {/* Title skeleton */}
          <Skeleton variant="text" height={30} width="70%" />

          {/* Body skeleton */}
          <Skeleton variant="rectangular" height={50} width="100%" />
        </Box>
      ))}
    </Box>
  );
}

export default LoadingSkeleton;